import "../css/app.css"
import { createApp, h } from "vue"
import { createInertiaApp } from "@inertiajs/vue3"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m"
import VueGoogleMaps from "@fawmi/vue-google-maps"
import PrimeVue from "primevue/config"
import ToastService from "primevue/toastservice"
import "primevue/resources/themes/lara-light-blue/theme.css"
import FocusTrap from "primevue/focustrap"
import * as Sentry from "@sentry/vue"

createInertiaApp({
	title: (title) => (title ? `SNO Ads | ${title}` : "SNO Ads"),
	resolve: (name) =>
		resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
	setup({ el, App, props, plugin }) {
		const app = createApp({ render: () => h(App, props) })

		Sentry.init({
			app,
			dsn: "https://7e9ec1e765078db3de34699e82cec0a2@o258138.ingest.us.sentry.io/4507703382179840",
			integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
			// Performance Monitoring
			tracesSampleRate: 1.0, //  Capture 100% of the transactions
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
			// Session Replay
			replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
			replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		})

		app.use(plugin)
			.use(ZiggyVue, Ziggy)
			.use(PrimeVue)
			.directive("focustrap", FocusTrap)
			.use(VueGoogleMaps, {
				load: {
					key: "AIzaSyDTPCY3MqL_4MMh3eQJmbZXHENEvfzNVdA",
				},
			})
			.use(ToastService)
			.mount(el)
	},
	progress: {
		color: "#336699",
	},
})
